import { useEffect, useState } from "react";
import { Container, Image, Nav, Navbar, } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from '../assets/images/logo.png'

export function Header() {
    const [data, setData] = useState([]);
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY >= 100);
          });

        fetch('modckdata/navi.json')
            .then((res) => res.json())
            .then((res) => setData(res))
    }, [])
    return (
        <Navbar className={`layout-header ${scroll? 'bg-white': ''}`} fixed="top" expand="lg">
            <Container>
                <div className="mr-lg-auto">
                    <Navbar.Toggle  ></Navbar.Toggle>
                    <Nav.Link as={NavLink} to="/" className="p-0 d-inline logo">
                        <Image src={logo} alt="logo" className="logo" />
                    </Nav.Link>
                </div>

                <Navbar.Collapse id="navbarScroll" className="ml-auto">
                    {
                        data.map((item, i) => (
                            <Nav className="ml-auto" key={i}>
                            {
                                item.headerNavi.map((item, i) => (
                                    <Nav.Link key={i} as={NavLink} to={item.link}>{item.label}</Nav.Link>
                                ))
                            }
                           </Nav>
                        ))
                    }
                     </Navbar.Collapse>
                     <Nav.Link as={NavLink} to="login" className="btn btn-default btn-primary header-login-buttn">Login</Nav.Link>


               
            </Container>
        </Navbar>
    )
}

export default Header;
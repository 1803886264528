import { lazy, Suspense } from "react"

const Loader = (Component) => (props) => {
    return (
        <Suspense fallback="Loading">
            <Component {...props} />
        </Suspense>
    )
}

const Home = Loader(lazy(() => import('./components/home/index')))
const About = Loader(lazy(() => import('./components/aboutus/index')))
const Benefits = Loader(lazy(() => import('./components/benefits/index')))
const Contact = Loader(lazy(() => import('./components/contactus/index')))
const Events = Loader(lazy(() => import('./components/events/index')))
const Pricing = Loader(lazy(() => import('./components/pricing/index')))
const Service = Loader(lazy(() => import('./components/service/index')))
const Login = Loader(lazy(() => import('./components/login/index')))
const Jobs = Loader(lazy(() => import('./components/Jobs/index')))

export const routes = [
     {
         path: "*",
         element: <Home />
     },
     {
        path: "aboutus",
        element: <About />
    },
    {
        path: "benefits",
        element: <Benefits />
    },
    {
        path: "contactus",
        element: <Contact />
    },
    {
        path: "events",
        element: <Events />
    },
    {
        path: "pricing",
        element: <Pricing />
    },
    {
        path: "services",
        element: <Service />
    },
    {
        path: "login",
        element: <Login />
    },
    {
        path: "jobs",
        element: <Jobs />
    }
] 

import { useEffect, useState } from 'react';
import { Button, Nav } from 'react-bootstrap';
import { useRoutes } from 'react-router-dom';
import './App.css';
import './assets/css/responsive.css';
import Footer from './layout/footer';
import Header from './layout/header';
import { routes } from './router';

function App() {
  const pages = useRoutes(routes);
  const [buttonVisible, setButtonVisible] = useState(false)
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setButtonVisible(window.scrollY >= 100);
    });
  })

  return (
    <div className="App d-flex flex-column h-100">
      <Header />
      <main className='page-group flex-shrink-0'>
        {pages}
      </main>
      <Footer />
      <div className="sticky-right">
        <Nav.Link  as="a" href= { process.env.REACT_APP_REDIRECT_URL+"/candidate/register/cHVibGljcG9zdA==" } className="d-flex align-items-center p-0"><span className="icon-sticky icon-profile"></span><span className="sticky-text">Post your Resume</span></Nav.Link>
        {/* <Nav.Link as="a" href= { process.env.REACT_APP_REDIRECT_URL+"/job/add/cHVibGljcG9zdA==" } className="d-flex align-items-center p-0"><span className="icon-sticky icon-suitecase"></span><span className="sticky-text">Post your Job</span></Nav.Link> */}
      </div>
      <Button className={`button-top ${buttonVisible? 'active': ''}` } onClick={scrollTop}></Button>
    </div>
  );
}

export default App;

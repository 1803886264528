import { Col, Container, Image, Nav, Row } from "react-bootstrap";
import footerAboutImg from '../assets/images/aboutus-footer.jpg';


export default function Footer() {


    return (
        <footer className="footer mt-auto">
            <div className="footer-top">
                <Container>
                    <Row>
                        <Col sm={12} md={12} lg={4} xl={4}>
                            <h5 className="h5">About Us</h5>
                            <Row>
                                <Col sm={12} md={2} lg={4} xl={4}>
                                    <div className="footer-image">
                                        <Nav.Link as="a" className="p-0" href="/aboutus">
                                            <Image alt="aboutus" src={footerAboutImg} />
                                        </Nav.Link>
                                    </div>
                                </Col>
                                <Col sm={12} md={10} lg={8} xl={8}>
                                    <div>We, at Talent Turbo, aim to fill in the gap of information loss, revenue loss, and
                                        limitations of alternate sources of candidates. </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} md={4} lg={2} xl={2}>
                            <h5 className="h5">Services</h5>
                            <ul className="footer-link-list footer-link-service">
                                <li><Nav.Link className="p-0" href="/services#service1" title="Permission">Permission</Nav.Link></li>
                                <li><Nav.Link className="p-0" href="/services#service2" title="IaaS">IaaS</Nav.Link></li>
                                <li><Nav.Link className="p-0" href="/services#service3" title="Uber Model Screening">Uber Model Screening</Nav.Link></li>
                                <li><Nav.Link className="p-0" href="/services#service4" title="PaaS">RaaS</Nav.Link></li>
                                <li><Nav.Link className="p-0" href="/services#service5" title="PaaS">PaaS</Nav.Link></li>
                            </ul>
                        </Col>
                        <Col sm={12} md={4} lg={3} xl={3}>
                            <h5 className="h5">Rewards and Benefits</h5>
                            <ul className="footer-link-list footer-link-rewards">
                                <li><Nav.Link href="/benefits#benefit1" className="p-0" title="Monetization Ecosystem">Monetization Ecosystem</Nav.Link></li>
                                <li><Nav.Link href="/benefits#benefit2" className="p-0" title="For Referral">For Referral</Nav.Link></li>
                                <li><Nav.Link href="/benefits#benefit3" className="p-0" title="For Interviewers">For Interviewers</Nav.Link></li>
                                <li><Nav.Link href="/benefits#benefit4" className="p-0" title="Pay per screening">Pay per screening</Nav.Link></li>
                            </ul>

                        </Col>
                        <Col sm={12} md={4} lg={2} xl={3}>
                            <h5 className="h5">Contact</h5>
                            <p>691 S Milpitas Blvd, Milpitas, <br />
                                CA 95035, USA.</p>
                            <Nav.Link as="a" href="mailto:contact@talentturbo.us" className="icon-mail">contact@talentturbo.us</Nav.Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-bottom ">
                <div className="container d-flex align-items-center justify-content-between h-100">
                    <div>
                        &copy; <span id="year"></span> Talent Turbo. All Rights Reserved.
                        <Nav.Link as="a" href={process.env.REACT_APP_REDIRECT_URL + "/privacy-policy"} className="link-blue p-0 d-inline">Privacy Policy</Nav.Link>|
                        <Nav.Link as="a" href={process.env.REACT_APP_REDIRECT_URL + "/terms-of-service"} className="link-blue p-0 d-inline">Terms & Conditions</Nav.Link>
                    </div>
                    <div>
                        <Nav.Link href="https://m.facebook.com/talentturbo/?_rdr" target="_blank" className="icon-social icon-social-facebook"></Nav.Link>
                        <Nav.Link href="https://www.linkedin.com/company/talentturbo/?originalSubdomain=in" target="_blank" className="icon-social icon-social-in"></Nav.Link>
                        {/* <Nav.Link href="#" target="_blank" className="icon-social icon-social-youtube"></Nav.Link>
                        <Nav.Link href="#" target="_blank" className="icon-social icon-social-insta"></Nav.Link> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}